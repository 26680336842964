<template>
  <div>
    <img v-lazy="lazyLogo" class="logoWrapper">
    <h1>Zusammenfassung</h1>
    <p v-if="invoiceLoaded">
      Übersicht über die Zahlung.
      <br>
      <br>
      <b>Rechnung</b> {{invoiceYear}} / {{invoiceNumber}}
      <br>
      <b>Kundennummer</b> {{invoiceNumber.substring(0, 3)}}
      <br>
      <b>Rechnungsnummer</b> {{invoiceNumber.substring(3, 6)}}
      <br>
      <b>Betrag</b> {{invoice.total}}
      <br>
      <b>Token</b> {{token}}
    </p>
    <div v-else>
      <p>Rechnung wird geladen…</p>
      <b-spinner/>
    </div>
    <b-button v-if="!creatingPayment && invoiceLoaded" @click="next" variant="primary" :disabled="!valid" style="margin-top: 1rem;">Zur Zahlung</b-button>
    <div v-if="creatingPayment">
      <b-spinner small />
      <br>
      Zahlung wird erstellt
    </div>
  </div>
</template>

<script>
export default {
  name: 'Token',
  data() {
    return {
      invoiceYear: '',
      invoiceNumber: '',
      token: '',
      invoice: {},
      invoiceLoaded: false,
      creatingPayment: false,
      lazyLogo: {
        src: 'logo.png',
        loading: 'logo-min.png'
      },
    };
  },
  mounted() {
    if (this.$route.query.code) {
      const codeString = Buffer.from(this.$route.query.code, 'base64').toString('ascii');
      const codeArr = codeString.split(':');
      this.invoiceYear = codeArr[0];
      this.invoiceNumber = codeArr[1];
      this.token = codeArr[2];
    } else if (this.$route.params.token && this.$route.params.invoiceNumber && this.$route.params.invoiceYear) {
      this.invoiceYear = this.$route.params.invoiceYear;
      this.invoiceNumber = this.$route.params.invoiceNumber;
      this.token = this.$route.params.token;
      const codeString = `${this.invoiceYear}:${this.invoiceNumber}:${this.token}`;
      this.code = Buffer.from(codeString).toString('base64');
    } else {
      return this.$router.push('/');
    }
    this.$router.replace('/summary')
    this.getInvoice();
  },
  methods: {
    getInvoice() {
      const codeString = `${this.invoiceYear}:${this.invoiceNumber}:${this.token}`;
      const code = Buffer.from(codeString).toString('base64');
      this.axios.get(`invoices/getViaToken?code=${code}`, ).then((res) => {
        this.invoice = res.data;
        this.invoiceLoaded = true;
        if (res.data.payed) this.$router.push('/recievedpayment');
      })
      .catch(() => {
        this.error('Die angegebenen Daten sind ungültig')
      });
    },
    next() {
      this.creatingPayment = true;
      const codeString = `${this.invoiceYear}:${this.invoiceNumber}:${this.token}`;
      const code = Buffer.from(codeString).toString('base64');
      this.axios.get(`invoices/createOnlinePayment?code=${code}`, )
        .then((res) => {
          if (res.status !== 200) return;
          const url = res.data;
          if (url) window.location.href = url;
          else throw Error('no url given')
        }).catch(() => this.error('Fehler bei der Zahlungserstellung'));
    },
  },
  computed: {
    valid() {
      const valid = this.invoiceYear 
        && this.invoiceNumber
        && this.invoiceYear.length === 4
        && this.invoiceNumber.length === 6
        && !isNaN(this.invoiceYear)
        && !isNaN(this.invoiceNumber)
        && this.token
      return valid;
    }
  },
}
</script>

<style scoped>
</style>